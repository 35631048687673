.sectionKnowWeEstructure{
  /* padding-top: 200px; */
  padding: 100px 0;
  background-color: #efefef;
}

.radiusVideo{
  border-radius: 50px;
}

.whoWereSectionbox{
  background: rgb(8,68,105);
background: linear-gradient(90deg, rgba(8,68,105,1) 0%, rgba(24,135,219,1) 35%, rgba(8,68,105,1) 100%);
  color: #063e61;
  opacity: 1.48;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  padding-bottom: 100px;
  padding-top: 100px;
}