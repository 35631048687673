.brand{
  /* background-color: #084469; */
  background-color: #022032;
}
.navbar-nav {
  width: 100%;
  justify-content: end;
  font-family: var(--primary-poppins);
  font-weight: 400;
}
.navbar-nav > a {
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 10px;
  margin-right: 10px;
}
.justDropDown {
  margin-left: 10px!important;
}

@media (max-width: 420px)
{
  .brand {
   display: none;
  }
  
}