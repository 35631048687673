.navBar {
  background-color: #063E61;
}

.colorTitleNav {
  color: white;
  text-decoration: none;
}

.colorTitleNav:hover {
  color: white;
}

.justDropDown{
  margin: 7px;
}

@media (max-width: 420px)
{
  .navBar .container {
   justify-content: flex-start;
   flex-wrap: nowrap;
  }
  .navBar .container .navbar-brand img {
    width: 78%;
  }
}