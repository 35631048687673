.whoWereSectionBox {
  color: #063e61;
}

.whoWereSectionBox .boxWeArCustom {
  margin-bottom: 10px;
}

.boxWeArCustom ul {
  padding: 0;
}

.boxWeArCustom ul li {
  list-style: none;
}
.boxWeArCustom path {
  color: #2ECC71;
}
.fontSize20 {
  font-size: 20px;
}
@media (max-width: 600px)
{
  .fontSize20
   {
    font-size: 16px;
   }
}