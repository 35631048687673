.textAbout {
  padding:56px 0px 0px 0px;
}

.positionWhoDo {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #efefef;
}

.radiusVideo{
  border-radius: 15px!important;
}