.mainnotfound {
  background-color: #0a1821;
  height: 79vh;
  width: 100vw;
  font-family: Roboto, Arial, sans-serif;
  color: #fff;
  text-align: center;
}
.not-found {
  width: 560px;
  height: 225px;
  margin-right: -10px;
}
.starry-sky {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.notfound-copy {
  color: #fff;
  position: absolute;
  top: 358px;
  right: 112px;
  text-align: right;
}
.h1Text {
   font-weight: 700;
  font-size: 18px;
}

.all-stars {
  animation: blinkblink 8s linear infinite;
}

@keyframes blinkblink {  
  50% { opacity: 0.1; }
}

@media (max-width: 647px) { 
  .moon {
    padding-top: -500px;
  }
}