.footerBox{
  gap: 4%;
}
.footerBox>* {
  width: 22%!important;
}

.backgroudColor{
  background-color: #027EC8;
}

.endTextcopyR{  
  padding-top: 174px;
    padding-left: 465px;
}

.paddingFooter{
  padding-top: 40px;
}
.paddingFooter a {
  text-decoration: none;
}
.paddingFooter a:hover {
  text-decoration: underline;
}
.address {
  display: block;
  font-size: 14px;
}
.footerBox  ul {
  padding: 0;
}

.footerBox ul li {
  list-style: none;
  margin-bottom: 10px;
}
.footerBox .img {
  width: 100%;
}

@media (max-width: 768px)
{
  .footerBox
   {
    flex-direction: column;
    display: block !important;
   }
   .footerBox>* {
      width: auto!important;
   }
   .linkImag{
    display: block;
    margin-bottom: 40px;
    /* width: 58%; */
    text-align: center;
    border-bottom: 1px solid #4e9bdd;
    padding-bottom: 20px;
   }
   .footerBox .img {
    width: 58%;
   }
}