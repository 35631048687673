.fixeTopNavs{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: grid;
}

.fontSize24{
  font-size: 24px;
}

.fontSize32{
  font-size: 32px;
}