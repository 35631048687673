.sectionBox {
  padding: 60px 0;
}

.elementor-icon-area path {
  background-color: #ffffff!important;
  color: #4C3EBD!important;
  fill: #4C3EBD!important;
}

.elementor-icon-work path {
  background-color: #ffffff!important;
  color: #2ECC71!important;
  fill: #2ECC71!important;
}

.elementor-icon-customer path {
  background-color: #ffffff!important;
  color: #FCAD3D!important;
  fill: #FCAD3D!important;
}
 