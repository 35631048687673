
.imgWallpapaer {
  width: 100%;
  height: auto;
}

.containerItems {
  position: relative;
  color: white;
  height: 100vh;
}


 .bottom-left-sub {
  font-size: 22px;  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  font-weight: 400;
}

.bottom-left-title {
  font-size: 92px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1;
} 

.boxContentIBanner {
  position: absolute;
  padding: 80px 40px 80px 50px;
  z-index: 1;
  bottom: 18%;
  background: #00000075;
  font-family: var(--primary-lato);
  font-weight: 700;
}

@media (max-width: 1280px) {
  .containerItems {
    position: relative;
    color: white;
    height: 72vh;
  }
}

@media (max-width: 912px) {
  .boxContentIBanner {
    padding: 80px 50px 80px 50px;
  }
  .bottom-left-title {
    font-size: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1;
    font-weight: 700;
  } 
}

@media (max-width: 768px)
{
  .boxContentIBanner {
    padding: 80px 30px 80px 30px;
  }
  .bottom-left-sub {
    font-size: 22px;  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
    font-weight: 400;
  }
  
  .bottom-left-title {
    font-size: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1;
  }
}

@media (max-width: 768px)
{
  .boxContentIBanner {
    padding: 80px 30px 80px 30px;
  }
  .bottom-left-sub {
    font-size: 14px;  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
    font-weight: 400;
  }
  
  .bottom-left-title {
    font-size: 46px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1;
  }
  .imgWallpapaer {
    width: 100%;
    height: 44vh;
    background-size: auto!important;
  }
}